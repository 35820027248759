


















import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

export default defineComponent({
  name: 'ChemSIU131Task2',
  components: {CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    question(): string {
      let versionNumber = this.taskState.getValueByName('version')?.content?.value ?? 1;
      let value = `${versionNumber}`;
      if (versionNumber === 1) {
        value = `$\\ce{^{54}_{25}Mn + ^0_{-1}e^- -> ^{54}_{X}Cr}$`;
      } else if (versionNumber === 2) {
        value = `$\\ce{^{97}_{44}Ru + ^0_{-1}e^- -> ^{97}_{X}Tc}$`;
      }
      return value;
    },
  },
});
